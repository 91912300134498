import * as Sentry from "@sentry/react";

export const SENTRY_DSN = "https://cab571af75f4c1439c150ed0fefbb3d3@o4505861972819968.ingest.sentry.io/4505867801133056"
export const initSentrySession = (env) => {
    if(env !== 'dev') {
        Sentry.init({
            dsn: SENTRY_DSN,
            environment: env,
            enabled: env !== 'dev',
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications',
                'The user aborted a request',
                'The operation was aborted',
                'Fetch is aborted'
            ],
            beforeSend(e, h) {
                const error = h.originalException;
                
                if (error && error.name === 'AbortError') {
                    return null;
                }

                return e;
            }
        });
    }
}