import 'react-hot-loader/patch'
import React from "react";
import ReactDOM from "react-dom";
import MainSearchBox from "./components/MainSearchBox";
import {initSentrySession} from "../../service/SentryService";
import { StyleContext, styleContextRawData } from '../../customHooks/useStyleContext';

const rootElement = document.getElementById("search-root");
export const SectionContext = React.createContext(sectionData)

const styles = styleContextRawData();
initSentrySession(styles?.env ?? 'dev');

const renderApp = (Component) => {
    if (!rootElement) {
        return;
    }
    
    ReactDOM.render(
        <SectionContext.Provider value={sectionData}>
            <StyleContext.Provider value={styles}>
                <Component />
            </StyleContext.Provider>
        </SectionContext.Provider>,
        rootElement
    );
};

renderApp(MainSearchBox);
