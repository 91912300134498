import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {SectionContext} from "../search-box";
import {Button, Col, Row} from "react-bootstrap";

import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations'
import ModalContainer from "../../components/SearchModal/SearchModal";
import apiService from "../../../service/api";

import cx from "classnames"

import "./search-box.scss"
import classes from "./search-box.scss.json"

import {
    isValid,
    format,
    addDays,
    parseISO,
    differenceInDays,
    addHours,
    addMonths,
    min,
    isBefore,
    isAfter, differenceInHours, startOfDay, getDay, setHours, setMinutes
} from "date-fns";
import frLocale from "date-fns/locale/fr"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faClock, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import sandbox from "../../../service/Sandbox";
import { useStyleContext } from '../../../customHooks/useStyleContext';

const FadeInDiv = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;

const MainSearchBox = () => {

    const containerRef = useRef()
    const sectionContext = useContext(SectionContext)
    const styleContext = useStyleContext()
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [selectedInput, setSelectedInput] = useState(null)
    const [toggleModal, setToggleModal] = useState(true)

    const [agencyList, setAgencyList] = useState([])
    const [bookingParameters, setBookingParameters] = useState({})
    const [minDays, setMinDays] = useState(0)
    const [minHours, setMinHours] = useState(0)
    const [minTimeBeforeStart, setMinTimeBeforeStart] = useState(3)
    const [maxTimeBeforeStart, setMaxTimeBeforeStart] = useState(12)

    const [searchParams, setSearchParams] = useState({
        start_date: '',
        end_date: '',
        start_agency: '',
        end_agency: '',
    })

    const [inputState, setInputState] = useState({
        start_agency : '',
        start_day: '',
        start_hour: '',
        end_agency: '',
        end_day: '',
        end_hour: ''
    })

    const [searchButtonDisabled, setSearchButtonDisabled] = useState({
        min_days: false,
        min_time_before_start: false,
        max_time_before_start: false
    })

    const [selectedStartDate, setSelectedStartDate] = useState(new Date())

    const minStartDate = useMemo(() => addHours(new Date(), minTimeBeforeStart), [minTimeBeforeStart]);
    const maxStartDate = useMemo(() => addMonths(new Date(), maxTimeBeforeStart), [maxTimeBeforeStart]);


    useEffect(() => {
        getAgencyList()
    }, [])

    useEffect(() => {
        const dateObj = parseISO(searchParams.start_date)
        setSelectedStartDate( isValid(dateObj) ? dateObj : new Date())
    }, [searchParams.start_date]);

    useEffect(() => {
        let startDate = parseISO(searchParams.start_date)
        let endDate =  parseISO(searchParams.end_date)
        console.log("helloooo", {startDate, endDate, minTimeBeforeStart, maxTimeBeforeStart, minDays})
        let maxEndDate = addMonths(new Date(), maxTimeBeforeStart)

        if(isValid(startDate) && isValid(endDate)) {
            if( differenceInDays(endDate, startDate) < minDays) {
                endDate = min([addDays(startDate, minDays), maxEndDate])
            } else {
                endDate = min([startDate, maxEndDate])
            }
        } else {
            // startDate = addHours(new Date(), minTimeBeforeStart)
            // endDate = min([addDays(startDate, minDays),maxEndDate])
        }
        console.log("helloooo222", {startDate, endDate, maxEndDate})

        const startAgency = agencyList?.find(ag => ag.id === searchParams?.start_agency)
        if(startAgency?.availability_periods?.length) {
            let hour = null
            let minute = 0
            const day = getDay(startDate)
            console.log({day})
            startAgency?.availability_periods?.forEach(period => {
                if (period?.day === day && period.status === 'available' && hour === null) {
                    hour = Number(period?.start_hour?.split(':')[0])
                    minute = Number(period?.start_hour?.split(':')[1])
                }
            })
            console.log({hour})
            if(hour) {
                startDate = setHours(startDate, hour);
                endDate = setHours(endDate, hour);
            }
            startDate = setMinutes(startDate, minute);
            endDate = setMinutes(endDate, minute);
        }
        console.log("Hello workd", {startAgency, startDate, endDate})

        if(isValid(startDate) && isValid(endDate)) {
            setSearchParams({
                ...searchParams,
                start_date: format(startDate, "yyyy-MM-dd HH:mm"),
                end_date:  format(endDate, "yyyy-MM-dd HH:mm"),
            })
            setInputState({
                ...inputState,
                start_day: format(startDate, 'dd/MM/yyyy'),
                start_hour: format(startDate, "HH:mm"),
                end_day: format(endDate, 'dd/MM/yyyy'),
                end_hour: format(endDate, "HH:mm"),
            })
        }
    }, [minDays, minTimeBeforeStart, maxTimeBeforeStart, searchParams?.start_agency]);

    useEffect(() => {
        if(searchParams?.start_date && searchParams?.end_date) {
            setSearchButtonDisabled(prevState => {
                return {...prevState, min_days: checkMinDays()}
            })
        }
    }, [searchParams, searchParams, minDays])

    // useEffect(() => {
    //     console.log("hoho", searchButtonDisabled)
    // }, [searchButtonDisabled])

    useEffect(() => {
        if(agencyList?.length === 1) {
            setSearchParams(prevState => {
                return {...prevState,
                    start_agency: agencyList?.[0]?.id,
                    end_agency: agencyList?.[0]?.id,
                }
            })
        }
        else if(agencyList?.length > 1) {
            const defaultAgency = agencyList?.find(agency => agency?.is_default === true)
            if(defaultAgency) {
                console.log("default", defaultAgency)
                setSearchParams(prevState => {
                    return {...prevState,
                        start_agency: defaultAgency?.id ?? '',
                        end_agency: defaultAgency?.id ?? '',
                    }
                })
                setInputState(prevState => {
                    return {...prevState,
                        start_agency: sandbox.getAgencyName([defaultAgency], defaultAgency?.id) ,
                        end_agency: sandbox.getAgencyName([defaultAgency], defaultAgency?.id)
                    }
                })
                updateBookingParametersWithAgency(defaultAgency)
            } else {
                updateBookingParametersWithAgency()
            }

        }
    }, [agencyList, bookingParameters])

    useEffect(() => {
        setSearchButtonDisabled(prevState => {
            return {...prevState,
                min_time_before_start: isBefore(selectedStartDate, minStartDate),
                max_time_before_start: isAfter(selectedStartDate, maxStartDate)
            }
        })
    }, [selectedStartDate]);


    const getAgencyList = () => {
        apiService.get("/api/search/agency-list", [], false)
            .then((result) => {
                console.log("Agency List")
                if(result.agencies) {
                    setAgencyList(result.agencies)
                }
                if(result.booking_parameters) {
                    setBookingParameters(result.booking_parameters)
                }
            })
    }

    const updateBookingParametersWithAgency = (agency) => {

        let bookingMinDays = bookingParameters?.min_days ?? 0
        let bookingMinHours = bookingParameters?.min_hours ?? 0
        let bookingMinTimeBeforeStart = bookingParameters?.min_time_before_start ?? 3
        let bookingMaxTimeBeforeStart = bookingParameters?.max_time_before_start ?? 12

        console.log(agency)
        agency?.min_days && (bookingMinDays = agency?.min_days)
        agency?.min_hours && (bookingMinHours = agency?.min_hours)
        agency?.min_time_before_start && (bookingMinTimeBeforeStart = agency?.min_time_before_start)
        agency?.max_time_before_start && (bookingMaxTimeBeforeStart = agency?.max_time_before_start)

        console.log({bookingMinDays,bookingMinHours,bookingMinTimeBeforeStart,bookingMaxTimeBeforeStart})
        setMinDays(bookingMinDays)
        setMinHours(bookingMinHours)
        setMinTimeBeforeStart(bookingMinTimeBeforeStart)
        setMaxTimeBeforeStart(bookingMaxTimeBeforeStart)
    }
    const handleInputClick = (name) => {
        setSelectedInput(name)
        setToggleModal(prevState => !prevState)
    }

    const handleSearchModalChange = (data) => {
        // console.log(data)
        let newState = {...inputState}
        let newSearchParams = {...searchParams}
        if(data.start_agency) {
            const startAgency = agencyList?.find((item) => item.id === parseInt(data.start_agency) )
            if(startAgency) {


                let agencyName = startAgency?.name
                if( startAgency?.public_name && startAgency?.public_name !== '') {
                    agencyName = startAgency?.public_name
                }
                if(agencyName !== inputState?.start_agency) {
                    newState = {...newState, start_agency : agencyName }
                    newSearchParams = {...newSearchParams, start_agency: startAgency.id}
                    updateBookingParametersWithAgency(startAgency)
                }
            }
        }
        if(data.end_agency) {
            const endAgency = agencyList?.find((item) => item.id === parseInt(data.end_agency) )
            if(endAgency) {
                let agencyName = endAgency?.name
                if(endAgency?.public_name && endAgency?.public_name !== '') {
                    agencyName = endAgency?.public_name
                }
                newState = {...newState, end_agency : agencyName }
                newSearchParams = {...newSearchParams, end_agency: endAgency.id}
            }
        }
        if(data.start_date && isValid(data.start_date)) {
            newState = {...newState,
                start_day: format(data.start_date, 'dd/MM/yyyy'),
                start_hour: format(data.start_date, 'HH:mm'),
            }
            newSearchParams = {...newSearchParams, start_date:  format(data.start_date, 'yyyy-MM-dd HH:mm')}
        }
        if(data.end_date) {
            if(isValid(data.end_date)) {
                newState = {...newState,
                    end_day: format(data.end_date, 'dd/MM/yyyy'),
                    end_hour: format(data.end_date, 'HH:mm'),
                }
                newSearchParams = {...newSearchParams, end_date:  format(data.end_date, 'yyyy-MM-dd HH:mm')}
            } else {
                newSearchParams = {...newSearchParams, end_date:  ''}
            }
        }
        else {
            newState = {...newState,
                end_day: '',
                end_hour: '',
            }
            newSearchParams = {...newSearchParams, end_date:  ''}
        }
        setInputState(newState)
        setSearchParams(newSearchParams)
    }

    const handleButtonClick = () => {
        const urlParams = new URLSearchParams(searchParams);
        const uri = `/recherche?${urlParams.toString()}`

        if(checkMinDays()) {
            alert(`La durée minimale de location est de ${minDays} jours`)
            return
        }
        window.location.href = uri
    }

    const checkMinDays = () => {
        // console.log(searchParams)
        const startDate = parseISO(searchParams.start_date)
        const endDate = parseISO(searchParams.end_date)
        const dayDiff = differenceInDays(endDate, startDate)
        const hourDiff = differenceInHours(endDate, startDate)
        // console.log(dayDiff)
        // console.log(hourDiff)
        // console.log(Math.ceil(hourDiff/24))

        if( minDays > 0 &&  Math.ceil(hourDiff/24) < minDays) {
            return true
        }
        return false
    }

    return (
        <FadeInDiv className={"p-2 p-lg-4  w-100"} ref={containerRef}>
            <form onSubmit={handleSubmit(handleButtonClick)}>
                {(agencyList?.length > 1 && styleContext?.app_config?.single_agency && !styleContext?.app_config?.disable_agency_choice) && (
                    <div className="mb-2">
                        <div className={classes["input-wrapper"]}>
                            <input
                                value={inputState.start_agency}
                                onClick={() => handleInputClick('start_agency')}
                                className={cx(classes['place-input'], "agency-select form-control bg-white")}
                                onChange={() =>{}}
                                placeholder={"Sélectionner l'agence de départ"}
                                name="start_agency"
                                {...register("start_agency")}
                                required
                            />
                            <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(classes["input-icon"],"c-grey fs16")}/>
                        </div>
                    </div>
                )}
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-center w-100">
                    <div className="m-2">
                        <p className="medium mb-2">{sectionContext?.contents?.['home-search-start'] ?? 'DEPART'}</p>
                        {(agencyList?.length > 1 && !styleContext?.app_config?.single_agency && !styleContext?.app_config?.disable_agency_choice) && (
                            <div className="mb-2">
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        value={inputState.start_agency}
                                        onClick={() => handleInputClick('start_agency')}
                                        className={cx(classes['place-input'], "agency-select form-control bg-white")}
                                        onChange={() =>{}}
                                        placeholder={"Sélectionner l'agence de départ"}
                                        name="start_agency"
                                        {...register("start_agency")}
                                        required
                                    />
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </div>
                        )}
                        <Row className={"fs12 mb-4"}>
                            <Col xs={7}  className={""}>
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        value={inputState.start_day}
                                        onClick={() => handleInputClick('start_day')}
                                        className={cx(classes['date-input'], "form-control bg-white")}
                                        name="start_day"
                                        {...register("start_day")}
                                        onChange={() => {}}
                                        placeholder={"__ /__ /____"}
                                        required
                                    />
                                    <FontAwesomeIcon icon={faCalendarAlt} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </Col>
                            <Col xs={5} className={""}>
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        value={inputState.start_hour}
                                        onClick={() => handleInputClick('start_hour')}
                                        className={cx(classes['time-input'],"form-control bg-white")}
                                        name="start_hour"
                                        onChange={() => {}}
                                        placeholder={"__:__"}
                                        required
                                    />
                                    <FontAwesomeIcon icon={faClock} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="m-2">
                        <p className="medium  mb-2">{sectionContext?.contents?.['home-search-end'] ?? 'RETOUR'}</p>
                        {(agencyList?.length > 1 && !styleContext?.app_config?.single_agency && !styleContext?.app_config?.disable_agency_choice) && (
                            <div className=" mb-2">
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        disabled={styleContext?.app_config?.single_agency ?? false}
                                        value={inputState.end_agency}
                                        onClick={() => handleInputClick('end_agency')}
                                        className={cx(classes['place-input'], "agency-select form-control bg-white")}
                                        name="end_agency"
                                        placeholder={"Sélectionner l'agence de retour"}
                                        onChange={() => {}}
                                        required
                                    />
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </div>
                        )}
                        <Row className={"fs12 mb-4"}>
                            <Col xs={7} className={""}>
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        value={inputState.end_day}
                                        onClick={() => handleInputClick('end_day')}
                                        className={cx(classes['date-input'], "form-control bg-white")}
                                        onChange={() => {}}
                                        name="end_day"
                                        placeholder={"__ /__ /____"}
                                    />
                                    <FontAwesomeIcon icon={faCalendarAlt} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </Col>
                            <Col xs={5} className={""}>
                                <div className={classes["input-wrapper"]}>
                                    <input
                                        value={inputState.end_hour}
                                        onClick={() => handleInputClick('end_hour')}
                                        className={cx(classes['time-input'], "form-control bg-white")}
                                        onChange={() => {}}
                                        name="end_hour"
                                        placeholder={"__:__"}
                                    />
                                    <FontAwesomeIcon icon={faClock} className={cx(classes["input-icon"],"c-grey fs16")}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {searchButtonDisabled.min_days && (
                    <div className={"mb-2"} >
                        <p className={"text-center fst-italic medium"}>La durée minimale de location est de {minDays} jours</p>
                    </div>
                )}
                {isBefore(startOfDay(selectedStartDate), startOfDay(minStartDate)) && (
                    <div className={"mb-2"} >
                        {/*<p className={"text-center fst-italic medium"}>L'agence n’accepte les reservation qu’après le {format(minStartDate,'dd MMMM yyyy', {locale: frLocale})}</p>*/}
                        <p className={"text-center fst-italic medium"}>Veuillez sélectionner une date de réservation à partir du {format(minStartDate,'dd MMMM yyyy', {locale: frLocale})} pour cette agence</p>
                    </div>
                )}
                {isAfter(startOfDay(selectedStartDate), startOfDay(maxStartDate)) && maxStartDate && (
                    <div className={"mb-2"} >
                        {/*<p className={"text-center fst-italic medium"}>L'agence n’accepte les reservation qu’avant le {format(maxStartDate,'dd MMMM yyyy', {locale: frLocale})}</p>*/}
                        <p className={"text-center fst-italic medium"}>Les réservations sont possibles jusqu'à {maxTimeBeforeStart} mois à l'avance pour cette agence.</p>
                    </div>
                )}
                <div className={"d-flex justify-content-center"}>
                    <Button
                        type={'submit'}
                        variant={"primary"}
                        className={"p-3 w-100 text-nowrap"}
                        // disabled={searchButtonDisabled?.min_days || searchButtonDisabled?.min_time_before_start || searchButtonDisabled?.max_time_before_start}
                    >{sectionContext?.contents?.['home-search-button'] ?? 'Rechercher'}</Button>
                </div>
            </form>
            <ModalContainer
                values={searchParams}
                selectedInput={selectedInput}
                toggleOpen={toggleModal}
                agencies={agencyList}
                onChange={handleSearchModalChange}
                color={styleContext?.colors?.['c-main']}
                bookingParameters={bookingParameters}
                singleAgency={styleContext?.app_config?.single_agency}
                minDays={minDays}
                minHours={minHours}
                minStartDate={minStartDate}
                maxStartDate={maxStartDate}
                maxTimeBeforeStart={maxTimeBeforeStart}
                disableAgencySelect={styleContext?.app_config?.disable_agency_choice}
                warningMessage={styleContext?.configs?.["content_config-search_warning_message"] ?? null}
            />
        </FadeInDiv>
    );
};

export default MainSearchBox;
