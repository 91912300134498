import React from "react";

export const styleContextRawData = () => {
    if (typeof page_data !== 'undefined') {
        return page_data;
    } else if (typeof websiteStyles !== 'undefined') {
        return websiteStyles;
    } else {
        return {};
    }
}

export const StyleContext = React.createContext(styleContextRawData());

export const useStyleContext = () => {
    return React.useContext(StyleContext);
}